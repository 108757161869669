import { lazy } from "react";



const Routes = [
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/login/admin",
        component: lazy(() => import('../loginpages/Login.js')),
    
    },
    {
        path: "/editarticle",
        component: lazy(() => import('../article/editarticle.js')),
        name: '/admin'
    },
    {
        path: "/article",
        component: lazy(() => import('../article/article.js')),
        name: '/admin'
    },
    {
        path: "/addarticle",
        component: lazy(() => import('../article/addarticle.js')),
        name: '/admin'
    },
    {
        path: "/aboutuser",
        component: lazy(() => import('../Aboutusers/aboutuserlist')),
        name: '/admin'
    },
    {
        path: "/addaboutuser",
        component: lazy(() => import('../Aboutusers/addaboutuser')),
        name: '/admin'
    },
    {
        path: "/editaboutuser",
        component: lazy(() => import('../Aboutusers/editaboutuser')),
        name: '/admin'
    },
    {
        path: "/dashboard",
        component: lazy(() => import('../dashboard/Dashboard.js')),
        name: '/admin'
    },
    // {
    //     path: "/nftdetails",
    //     component: lazy(() => import('../nftlist/nftList.js')),
    //     name: '/admin'
    // },
    {
        path: "/nftlist",
        component: lazy(() => import('../nftlist/nftList.js')),
        name: '/admin'
    },
    {
        path: "/markettokens",
        component: lazy(() => import('../nftlist/marketTokens.js')),
        name: '/admin'
    },
    {
        path: "/tokendetail",
        component: lazy(() => import('../nftlist/marketTokens.js')),
        name: '/admin'
    },
    {
        path: "/addcategory",
        component: lazy(() => import('../category/addcategory.js')),
        name: '/admin'
    },
    {
        path: "/editcategory",
        component: lazy(() => import('../category/addcategory.js')),
        name: '/admin'
    },
    {
        path: "/categorylist",
        component: lazy(() => import('../category/categorylist.js')),
        name: '/admin'
    },
    {
        path: "/currencylist",
        component: lazy(() => import('../currencyList/currencylist.js')),
        name: '/admin'
    },
    {
        path: "/addtoken",
        component: lazy(() => import('../currencyList/addtoken.js')),
        name: '/admin'
    },
    {
        path: "/editcms",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/cmslist",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../cmsAndFaq/editcms.js')),
        name: '/admin'
    },
    {
        path: "/cmsandfaq",
        component: lazy(() => import('../cmsAndFaq/cmsbox.js')),
        name: '/admin'
    },
    {
        path: "/addusers",
        component: lazy(() => import('../subscribers/addusers.js')),
        name: '/admin'
    },
    {
        path: "/subscriberslist",
        component: lazy(() => import('../subscribers/subscriberlist.js')),
        name: '/admin'
    },
    {
        path: "/sendmail",
        component: lazy(() => import('../subscribers/sendmail.js')),
        name: '/admin'
    },
    {
        path: "/sociallist",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/addsocial",
        component: lazy(() => import('../sociallinks/addsocial.js')),
        name: '/admin'
    },
    {
        path: "/editsociallink",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/servicefee",
        component: lazy(() => import('../serviceFeeManagement/listservicefee.js')),
        name: '/admin'
    },
    {
        path: "/referral",
        component: lazy(() => import('../referralpage/referrallist.js')),
        name: '/admin'
    },
  
    // {
    //     path: "/userlist",
    //     component: lazy(() => import('../userlist/userlist.js')),
    //     name: '/admin'
    // },
    {
        path: "/userlist",
        component: lazy(() => import('../user/userlist')),
        name: '/admin'
    },
    {
        path:"/userdetail",
        component: lazy(() => import('../user/userlist')),
        name:'/admin'
    },
    {
        path:"/tokenlist",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path:"/viewdetail",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path: "/faqlist",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq.js')),
        name: '/admin'
    },
    {
        path:"/addfaqcontent",
        component:lazy(()=>import('../faqlist/addfaqcontent')),
        name:'/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/editfaqcontent",
        component: lazy(() => import('../faqlist/editfaqcontent'))
    },
    {
        path: "/burnlist",
        component: lazy(() => import('../burnlist/burntokenlist.js')),
        name: '/admin'
    },
    {
        path: "/reportlist",
        component: lazy(() => import('../report/reportlist.js')),
        name: '/admin'
    },
    {
        path: "/viewreporttoken",
        component: lazy(() => import('../report/reportlist.js')),
        name: '/admin'
    },
    {
        path: "/nfttaglist",
        component: lazy(() => import('../nfttag/nfttaglist.js')),
        name: '/admin'
    },
    {
        path: "/editnfttag",
        component: lazy(() => import('../nfttag/nfttaglist.js')),
        name: '/admin'
    },
    {
        path: "/artist-list",
        component: lazy(() => import('../Artist/Artist')),
        name: '/admin'
    },
    {
        path: "/artist-save",
        component: lazy(() => import('../Artist/Artist')),
        name: '/admin'
    },
    {
        path: "/emailtemplatelist",
        component: lazy(()=> import('../emailtemplates/emailtemplatelist')),
        name:'/admin'
    },
    {
        path : "/emailtemplateedit/:slug",
        component : lazy(()=> import('../emailtemplates/emailtemplatelist')),
        name:'/admin'
    },
    {
        path: "/npo-list",
        component: lazy(() => import('../NPO/NPO')),
        name: '/admin'
    },
    {
        path: "/npo-save",
        component: lazy(() => import('../NPO/NPO')),
        name: '/admin'
    },{
        path: "/promo-drops",
        component: lazy(() => import('../PROMO/PROMODROPS')),
        name: '/admin'
    },
    ,{
        path: "/promo/:CollectionUrl",
        component: lazy(() => import('../PROMO/GENERATEPROMO')),
        name: '/admin'
    },
    ,{
        path: "/promo-code/:CollectionUrl/:TokenId",
        component: lazy(() => import('../PROMO/VIEWPROMO')),
        name: '/admin'
    },
    // {
    //     path: "/search/:key",
    //     component: lazy(()=>import('../')),
    //     name: '/public'
    // },
    {
        path: "/promotionlist",
        component: lazy(() => import('../promotion/tokenlist')),
        name: '/admin'
    },
]


export default Routes;

import axios from 'axios';
import config from "../lib/config.js";


export const loginAdmin = async(payload)=>{
    console.log("admin login ",payload,config.AdminAPI)
    let data = {"data":payload}
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }


}
export const getarticle=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getarticle`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

export const getaboutuser=async()=>{
    try{
        var resp = await axios({
                'method':'GET',
                'url':`${config.AdminAPI}/getaboutuser`,
        })
        console.log("member resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in article list",err)}
}

export const customtokenfetch=async(data)=>{
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/tokenfetchapi`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }
}

export const check  = async()=>{

    try{
    var data = {"test":"testdata"}
    var resp = await axios({
        "method":"POST",
        'url':`${config.AdminAPI}/checkroute`,
        "data":data,
        "headers":{
            "Authorization":localStorage.getItem("token")
        }
    })

    }
    catch(err){console.log("err".err)}

}